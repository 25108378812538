import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-text',
  templateUrl: './popover-text.component.html',
  styleUrls: ['popover-text.component.scss']
})
export class PopoverTextComponent implements OnInit {
  text: string = ''
  constructor(public popoverController: PopoverController) {}

  ngOnInit() {
  }
  static async show(popoverController:PopoverController, text: string, event: any ) {
      const popover = await popoverController.create({
        component: PopoverTextComponent,
        cssClass: 'custom-popover',
        componentProps: {
          text,
        },
        event: event,
        mode: 'ios',
        translucent: false
      });
      await popover.present();
      await popover.onDidDismiss();
  }

}
