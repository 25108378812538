import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const werk5CustomerGroup = 'werk5';
export const customerGroup = werk5CustomerGroup;

const supportEmail = {
	werk5: 'werk5@smoothr.de'
};
const firebaseConfig = {
	werk5: {
		apiKey: "AIzaSyB1Y4wG6BoFE2hi8irI30ofKfscXoEPPDw",
		authDomain: "prod-env-smoothr.firebaseapp.com",
		databaseURL: "https://prod-env-smoothr.firebaseio.com",
		projectId: "prod-env-smoothr",
		storageBucket: "prod-env-smoothr.appspot.com",
		messagingSenderId: "640058019537",
		appId: "1:640058019537:web:cf8eb9f7956417dbcbfff0",
		measurementId: "G-7X616EX21T"
	  }
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyCIFSYV5oAeVwZRRn5YqD_MxeIfs6DabBM',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment
};
