import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const werk5CustomerGroup = 'werk5';
export const customerGroup = werk5CustomerGroup;

const supportEmail = {
	werk5: 'werk5@smoothr.de',
};
const firebaseConfig = {
	werk5: {
		apiKey: "AIzaSyBx3rlbR5y81d9sWT4DJEfDlqFyqUmXaz0",
		authDomain: "dev-env-216009.firebaseapp.com",
		databaseURL: "https://dev-env-216009.firebaseio.com",
		projectId: "dev-env-216009",
		storageBucket: "dev-env-216009.appspot.com",
		messagingSenderId: "1004449889395",
		appId: "1:1004449889395:web:5c70e6f8805c544ae0fe06",
		measurementId: "G-C03BQT341K"
	  },
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyDp11Vc-uEBFhD3kgTGo2G9s1gr8rfPwUE',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
};
