import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';
import { SignInPage } from 'src/app/pages/sign-in/sign-in.page';

@Component({
  selector: 'app-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['login-button.component.scss']
})
export class LoginButtonComponent  {
  @Input() customerName: string = null
  @Input() white = false;
  constructor(
    private router: Router
  ) { }
  goToLogin() {
    SignInPage.navigate(this.router)
  }
}
