import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {environment} from 'src/environments/environment';
import Customer from 'src/smoothr-web-app-core/models/Customer';
import Order from 'src/smoothr-web-app-core/models/Order';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';

@Component({
	selector: 'app-order-page-toolbar',
	templateUrl: './order-page-toolbar.component.html',
	styleUrls: ['order-page-toolbar.component.scss']
})
export class OrderPageToolbarComponent implements OnInit {
	@Output()
	backClick = new EventEmitter<void>();
	@Output()
	close = new EventEmitter<void>();
	@Input()
	preorderType: PreorderType;
	@Input()
	title: string;
	@Input()
	hideElements: boolean;
	environment = environment;
	@Input() order: Order;
	@Input() venue: Venue;
	@Input() showSelect = false;
	@Output() openSelectTable = new EventEmitter<void>();
	@Input() customer: Customer = null
	@Input() showCustomerLogin: Boolean = false;

	preorderTypeEnum = PreorderType;
	constructor() {}

	ngOnInit() {
		console.log(this.preorderType, this.order, this.venue)
	}

	onBackClick() {
		this.backClick.emit();
	}
	onClose() {
		this.close.emit();
		this.backClick.emit();

	}
	openSelectTableModal() {
		this.openSelectTable.emit();
	}
}
