import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Router} from '@angular/router';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {sleep, venueAcceptsOrders} from '../../../smoothr-web-app-core/utils/utils';
import {MenuPage} from '../menu/menu.page';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import {ScanQrModal} from 'src/app/components/scan-qr/scan-qr.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SelectTableModalComponent} from 'src/app/components/select-table-modal/select-table-modal.component';
import { SignInPage } from '../sign-in/sign-in.page';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.werk5.html',
	styleUrls: ['./home.page.werk5.scss']
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	environment = environment;
	languageEnum = Language;

	loading = false;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	active: any = {};

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		public translate: TranslateService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		await router.navigateByUrl(HomePage.url);
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
	}

	ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.loadVenues();
	}

	ionViewDidEnter() {
		this.repository.order.emit(null);
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores(preorderType: PreorderType) {
		this.loading = true;
		console.log(await this.repository.getAllVenues());
		const venue = (await this.repository.getAllVenues()).find(ven => venueAcceptsOrders(ven, preorderType));
		if (!venue) {
			console.log('No available venues');
			this.loading = false;
			return;
		}
		await this.loadVenueAndCreateOrder(venue, null, preorderType);
	}

	async loadVenueAndCreateOrder(venue: Venue, address: Address, preorderType: PreorderType) {
		console.log('loadVenueAndCreateOrder(', venue, address, preorderType, ')');
		const loadedVenue = await this.repository.getVenue(venue._id);
		this.repository.createOrder(loadedVenue, address, preorderType, null);
		if (preorderType == PreorderType.INSIDE) {
			await this.openQrScan(venue);
			this.loading = false;
			return;
		}
		await MenuPage.navigate(this.router);
		this.loading = false;
	}

	loadVenues() {
		for (const preorderType of this.preorderTypes) {
			this.active[preorderType] = false;
		}
		new Promise<void>(async (resolve, reject) => {
			try {
				const venues = await this.repository.getAllVenues();
				for (const venue of venues) {
					for (const preorderType of this.preorderTypes) {
						console.log(venue);
						this.active[preorderType] = this.active[preorderType] || venueAcceptsOrders(venue, preorderType);
					}
				}
				resolve();
			} catch (e) {
				reject(e);
			}
		}).finally(() => (this.loading = false));
	}
	async goToMenu(type: PreorderType) {
		this.locateAndShowStores(type);
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	async openQrScan(venue: Venue) {
		const table = await ScanQrModal.show(this.modalCtrl, venue);
		console.log(table);
		await sleep(200);
		if (!table) {
			this.snackbarCtrl.open(this.translate.instant('menu_page.no_table'), null, {
				duration: 2000
			});
			return;
		}
		try {
			this.order.tableNumber = table.number;
			this.order.table = table._id;
			this.repository.order.emit(this.order);
			await MenuPage.navigate(this.router);
			await SelectTableModalComponent.show(this.modalCtrl, this.order);
		} catch (e) {
			console.error(e);
			this.snackbarCtrl.open(this.translate.instant('menu_page.venue_panic_or_closed'), null, {
				duration: 2000
			});
			return;
		}
	}
	goToLogin() {
		SignInPage.navigate(this.router);
	}
}
