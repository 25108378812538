import {ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, Output} from '@angular/core';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import {OrderListAction} from '../../enums/OrderListAction';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import {DisplayIdentifier} from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import Article from '../../../smoothr-web-app-core/models/Article';
import {ValidationUtils} from '../../../smoothr-web-app-core/utils/validation-utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import {getPrice, numberToCurrency} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-order-list',
	templateUrl: './order-list.component.html',
	styleUrls: ['order-list.component.scss']
})
export class OrderListComponent implements DoCheck {
	@Output() action = new EventEmitter<OrderListAction>();
	@Input() editable = true;
	OrderUtils = OrderUtils;
	isSomeOptionFullPrice = false;
	needsConfiguration = false;
	articleGroupPrice = 0;
	getPrice = getPrice;
	numberToCurrency = numberToCurrency;
	private prevPromoState = false;
	private prevQuantity = 1;
	ot = OrderType;
	constructor(private cd: ChangeDetectorRef) {}

	private _articleGroup: ArticleGroup;

	get articleGroup(): ArticleGroup {
		return this._articleGroup;
	}

	@Input()
	set articleGroup(value: ArticleGroup) {
		console.log(value)
		this._articleGroup = value;
		this.reloadArticle();
	}

	private _order: Order;

	get order(): Order {
		return this._order;
	}

	@Input()
	set order(value: Order) {
		this._order = value;
		this.reloadArticle();
	}

	edit() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.edit);
	}

	isEditable(): boolean {
		return this.editable;
	}

	delete() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.delete);
	}

	async note() {
		if (!this.editable) {
			return;
		}
		this.action.emit(OrderListAction.note);
	}

	add() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.add);
	}

	remove() {
		if (!this.isEditable()) {
			return;
		}
		this.action.emit(OrderListAction.remove);
	}

	isOptionFullPrice(articleOption: ArticleOption): boolean {
		const group = this.articleGroup.article.groups.find(grp => grp._id === articleOption.group);
		return (
			group &&
			group.displayIdentifiers &&
			group.displayIdentifiers.findIndex(di => DisplayIdentifier.fullPrice === di) >= 0
		);
	}

	hiddenInCart(article: Article): boolean {
		return article.tags && article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined;
	}

	reloadArticle() {
		this.needsConfiguration = false;
		this.isSomeOptionFullPrice = false;
		this.articleGroupPrice = 0;
		this.prevPromoState = false;
		this.prevQuantity = 1;
		if (!this.articleGroup || !this.order || !this.order.preorder) {
			this.articleGroupPrice = 0;
			return;
		}
		this.prevQuantity = this.articleGroup.quantity;
		this.prevPromoState = this.articleGroup.isPromo;
		this.needsConfiguration = !ValidationUtils.areGroupsValid(this.articleGroup, this.articleGroup.article.groups);
		for (const articleOption of this.articleGroup.groups) {
			if (this.isOptionFullPrice(articleOption)) {
				this.isSomeOptionFullPrice = true;
			}
		}
		this.articleGroupPrice = OrderUtils.totalPrice(this.articleGroup, this.order.type, this.order.preorder.type);
		if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && this.articleGroup.isPromo) {
			this.articleGroupPrice -= OrderUtils.bogoPrice(this.articleGroup, this.order.type, this.order.preorder.type);
		}
	}

	ngDoCheck(): void {
		if (this.prevPromoState !== this.articleGroup.isPromo) {
			this.reloadArticle();
			this.cd.markForCheck();
		}
		if (this.prevQuantity !== this.articleGroup.quantity) {
			this.reloadArticle();
			this.cd.markForCheck();
		}
	}
}
